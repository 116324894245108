import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { media } from 'util/constants';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  geo: {
    textAlign: 'center',
    paddingTop: '93.5px',
    height: '60vh',
    maxHeight: '400px',
    background: styles.backgroundUrl ? 'url('+styles.backgroundUrl+')' : '',
    backgroundColor: '#000000',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    [media.tablet]: {
      paddingTop: '122px'
    },

    '@media (min-width: 1150px)': {
      backgroundSize: 'contain',
    }
  },
  container: {
    background: "#000",
    color: "#d4090a",
    width: "100%",
    boxSizing: "border-box",
    display: "block",
    margin: "0 auto",
    maxWidth: "450px",
    padding: "50px 60px",

    [media.tablet]: {
      padding: "50px 85px"
    }
  },
  logotype: {
    height: "20px",
    paddingBottom: "10px"
  },
  headline: {
    fontFamily: 'DrukCyTT, sans-serif',
    fontSize: '32px',

    [media.tablet]: {
      fontSize: '48px'
    }
  },

  return: {
    display: 'block',
    marginTop: '20px',
    color: '#ffffff',
    fontFamily: 'Roboto, sans-serif',
    textDecoration: 'underline'
  }
});
