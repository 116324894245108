import * as ReactGA from 'react-ga';

let _isGoogleInitialized = false;

interface IGooglePageView {
  type: string;
  payload: string;
}

interface IGoogleEvent {
  type: string;
  payload: { category: string; action: string; label: string };
}

interface IGoogleModalView {
  type: string;
  payload: string;
}

let googleQueue: Array<IGoogleEvent | IGooglePageView | IGoogleModalView> = [];

export const initializeGoogleAnalytics = (gaId: string) => {

  if(!_isGoogleInitialized && gaId) {
    _isGoogleInitialized = true;
    ReactGA.initialize(gaId);
  }

  if (googleQueue.length > 0) {
    googleQueue.forEach((call: IGooglePageView | IGoogleEvent) => {
      switch (call.type) {
        case 'page':
          trackGooglePage((call as IGooglePageView).payload);
          break;
        case 'event':
          const { category, action, label } = (call as IGoogleEvent).payload;
          trackGoogleEvent(category, action, label);
          break;
        case 'modal':
          trackGoogleModal((call as IGoogleModalView).payload);
      }
    });

    googleQueue = [];
  }
};

export const isInitialized = () => {
  return _isGoogleInitialized;
};

export const trackGoogleEvent = (
  category: string,
  action: string,
  label: string = ''
) => {
  if (isInitialized()) {
    ReactGA.event({ category, action, label });
  }
};

export const trackGooglePage = (page: string) => {
  if (isInitialized()) {
    ReactGA.pageview(page);
  }
};

export const trackGoogleModal = (modal: string) => {
  if (isInitialized()) {
    ReactGA.modalview(modal);
  }
};

export const checkClickEvent = (evt: any) => {
  // Click inside the section article
  const section = evt.target.closest('section');
  if ( section?.className.indexOf('article_') > -1 ) {
    const currentTarget = evt.target.tagName.toLowerCase();
    const parentTarget = evt.target.parentElement && evt.target.parentElement.tagName.toLowerCase();
    // It's an article link
    if ( currentTarget === 'a' || parentTarget === 'a' ) {
      let action = evt.target.textContent;
      let label = evt.target.href;

      trackGoogleEvent('click', action, label);
    }
  }
};


export const addClickListener = () => {
  document.addEventListener('click', checkClickEvent, false);
};


export const removeClickListener = () => {
  document.removeEventListener('click', checkClickEvent, false);
};
