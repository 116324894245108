import React, { FC } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { styleSheet } from './style';
import { css } from 'aphrodite/no-important';
import { NavLink } from 'react-router-dom';
import { trackGoogleEvent } from 'util/google-helpers';


interface CarouselSlide {
    active: string;
    image: string;
    image_mobile: string;
    image_alt: string;
    link: string;
    type: string;
}
interface propsCarousel {
    data: any;
    routes: any;
}

const HeroCarousel: FC<propsCarousel> = ({data, routes}) => {
	const styles = styleSheet({});

	return (
		<Carousel className={css(styles.carousel)}
				  showThumbs={false}
				  showArrows={false}
				  showStatus={false}
				  autoPlay={true}
				  interval={6000}
				  infiniteLoop={true}>
            {data.filter((slide: CarouselSlide) => slide.active === "true").map((slide: CarouselSlide, index: number) => {
                if (slide.type === "internal") {
                    return <NavLink exact to={slide.link} key={index}
                        onClick={() => trackGoogleEvent( 'Click', 'carousel', slide.link)}
                        className={css( styles.link )}>
                        <img className="desktop"
							 alt={slide.image_alt}
							 src={slide.image}/>
						<img className="mobile"
							 alt={slide.image_alt}
							 src={slide.image_mobile}/>
                    </NavLink>
                }
                if (slide.type === "external") {
                    return <a className={css( styles.link )}
							  onClick={() => trackGoogleEvent( 'Click', 'carousel', slide.link)}
							  href={slide.link}
							  target="_blank"
							  rel="noopener noreferrer"
							  key={index}>
                        <img className="desktop"
							 alt={slide.image_alt}
							 src={slide.image}/>
						<img className="mobile"
							 alt={slide.image_alt}
							 src={slide.image_mobile}/>
                    </a>
                }
                return '';
            })}
		</Carousel>
	);
};

export default HeroCarousel;
