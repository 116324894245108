import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { media } from 'util/constants';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  app: {
    backgroundColor: '#212121',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    width: '100%',
  },
  wrapper: {
    flexGrow: 0,

    [media.tablet]: {
      background: '#212121',
      backgroundSize: '100%'
    }
  }
});
