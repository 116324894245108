import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { APP_MAX_WIDTH, media } from 'util/constants';
import { children } from 'util/style-helpers';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  article: {
    fontFamily: 'Roboto, sans-serif',
    color: '#ffffff',
    margin: '35px 15px',
    maxWidth: APP_MAX_WIDTH,

    [media.tablet]: {
      margin: '40px 25px'
    },

    [media.desktop]: {
      margin: '40px auto'
    },

    [children('a')]: {
      color: '#ffffff',
      textDecoration: 'underline'
    }
  },

  linkGroup: {
    margin: '5px 0',
    [children('.icon-arrow-up')]: {
      marginRight: '5px'
    }
  },

  links: {
    color: '#ffffff',
    textDecoration: 'underline',
    fontSize: '14px'
  },

  wrapper: {
    display: 'block'
  },

  image: {
    marginTop: '8px',
    textAlign: 'center',

    [children('img')]: {
      display: 'block',
      margin: '0 auto 12px',
      maxWidth: '100%',
      maxHeight: '190px',

      [media.tablet]: {
        maxHeight: '525px',
      },
    }
  },

  embed: {
    [children('iframe')]: {
      maxWidth: '100%'
    }
  },

  content: {
    marginTop: '8px',

    [media.tablet]: {
      marginTop: '23px'
    }
  },

  headline: {
    color: '#D02126',
    fontFamily: 'DrukCyTT',
    fontSize: '32px',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    marginBottom: '10px',

    [media.tablet]: {
      fontSize: '44px'
    }
  },

  paragraphs: {
    margin: '10px 0',

    ':first-child': {
      marginTop: '0'
    },

    [media.tablet]: {
      margin: '18px 0'
    }
  },

  list: {
    fontSize: '14px',
    lineHeight: '18px',

    [media.tablet]: {
      lineHeight: '20px',
    }
  }
});
