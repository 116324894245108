import React, { useEffect } from 'react';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { IComponentObject } from 'models/base';
import { trackGooglePage } from '../../util/google-helpers';
import { GA_PAGES } from '../../util/constants';

const Geo = (props:IComponentObject) => {
    const { data } = props;
    const styles = styleSheet({'backgroundUrl': props.data.settings.background_image ? props.data.settings.background_image : ''});

    useEffect(() => {
        trackGooglePage(GA_PAGES.GEO);
    });

    return (
      <section className={css(styles.geo)}>
        <div className={css(styles.container)}>
            { data.settings.logotype_image &&
            <img alt="logotype" className={css(styles.logotype)} src={data.settings.logotype_image} /> }
            { data.copy.headline &&
            <h1 className={css(styles.headline)}>{ data.copy.headline }</h1> }
            { data.copy.return && data.copy.return_url && data.copy.return_target &&
            <a className={css(styles.return)} href={data.copy.return_url} target={data.copy.return_target}>{data.copy.return}</a> }
        </div>
      </section>
    );
}

export default Geo;
