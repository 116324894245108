import { StyleSheet } from 'aphrodite/no-important';

export const style = () => StyleSheet.create({

  loading: {
    padding: '30px',
    textAlign: 'center',
    margin: 'auto'
  },

  loadingImage: {
    background: 'rgba(255,255,255,.7)',
    borderRadius: '100%',
    margin: 'auto'
  }
});
