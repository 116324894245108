import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { APP_MAX_WIDTH, media } from 'util/constants';
import { children } from '../../util/style-helpers';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({

  header: {
    position: 'relative',
    background: '#000000',
    color: '#ffffff',
    borderBottom: '2px solid #000000',
    padding: '10px 5px',
    boxSizing: 'border-box',

    [media.tablet]: {
      padding: '0 10px',
    }
  },

  wrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: APP_MAX_WIDTH,
    minHeight: '42px',


    [media.mobile]: {
      minHeight: '58px',
    },

    [media.tablet]: {
      minHeight: '48px',
    }
  },

  logo: {
    maxWidth: '110px',

    [media.mobile]: {
      maxWidth: '125px'
    },

    [media.tablet]: {
      maxWidth: '250px'
    }
  },

  flag: {
    width: '25px',
    [media.tablet]: {
      maxWidth: '30px'
    }
  },

  headerActions: {
    display: 'flex',
    position: 'relative'
  },

  links: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'DrukCyTT',

    [media.tablet]: {
      fontSize: '24px'
    }
  },

  linkNav: {
    color: '#ffffff',
    display: 'inline-block',
    marginRight: '5px',
    maxWidth: '50px',
    verticalAlign: 'middle',

    [media.mobile]: {
      marginRight: '15px',
      maxWidth: 'none',
      padding: '8px 0'
    }
  },

  linkSelected: {
    color: '#D02126'
  },

  hide: {
    display: 'none'
  },

  currentLocale: {
    fontFamily: 'DrukCyTT',
    fontSize: '18px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    minWidth: '45px',

    [children('span')]: {
      [children('svg')]: {
        width: '8px',

        [media.mobile]: {
          width: '12px'
        }
      }
    },

    [media.mobile]: {
      minWidth: '55px',
    },

    [media.tablet]: {
      fontSize: '16px'
    },

    [children('img')]: {
      marginRight: '5px'
    },

    [children('svg')]: {
      fill: '#ffffff',
      marginLeft: '3px'
    }
  },

  triggerMobile: {
    position: 'absolute',
    opacity: 0,
    right: 0,
    height: '100%',
    cursor: 'pointer',
    width: '60px',

    [media.desktop]: {
      display: 'none'
    },

    ':checked ~ ul': {
      display: 'flex'
    },
  },

  trigger: {
    display: 'none',

    [media.desktop]: {
      display: 'block',
      position: 'absolute',
      opacity: 0,
      right: 0,
      height: '100%',
      width: '50px',
      cursor: 'pointer',
    },

  ':hover ~ ul': {
      display: 'flex'
    }

  },

  localesDropdown: {
    display: 'none',
    listStyle: 'none',
    background: '#212121',
    fontSize: '14px',
    position: 'absolute',
    bottom: '0',
    right: '0',
    zIndex: 1,
    margin: '0',
    padding: '5px 10px',
    transform: 'translateY(100%)',

    ':hover': {
      display: 'flex'
    },

    [media.tablet]: {
      padding: '10px 20px',
    },

    [children('li')]: {
      display: 'flex',
      alignItems: 'center',

      [children('button')]: {
        fontSize: '18px',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'DrukCyTT',
        color: '#ffffff',
        background: 'transparent',
        border: 'none',
        outline: 'none',

        [children('img')]: {
          marginRight: '5px'
        }
      }
    }
  }
});
