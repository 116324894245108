import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { IGridObject, IGenericObject } from 'models/base';
import { ArrowUp } from 'util/icons';
import { trackGooglePage } from '../../util/google-helpers';
import { GA_PAGES } from '../../util/constants';

const Article = (props:IGridObject) => {
    const { data, list, routes } = props;
    const styles = styleSheet({});
    const params:IGenericObject = useParams();
    const article =  list.find((option:IGenericObject) => option.id === params.id);

    useEffect(() => {
        trackGooglePage(GA_PAGES.NEW_SINGLE);
    });

    const handleScrollClick = (e:any) => {
        e.preventDefault();
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    };

    window.scroll(0,0);

    return (
        <section className={css( styles.article )}>
            <div className={css( styles.linkGroup )}>
                <Link to={routes.NEWS}
                      className={css( styles.links )}>{data.copy.back_button_text}</Link>
            </div>
            <div className={css( styles.wrapper )}>
                <div>
                    {!!article && article.headline &&
                    <h1 className={css( styles.headline )} dangerouslySetInnerHTML={{__html: article.headline}}/>}
                </div>
                {!!article && article.image &&
                <div className={css( styles.image )}>
                    {article.image &&
                    <img src={article.image} alt={article.image_alt}/>}
                </div>}
                {!!article && article.embed &&
                <div className={css( styles.embed )} dangerouslySetInnerHTML={{__html: article.embed}}></div>}

                <div className={css( styles.content )}>
                    {!!article && article.paragraphs[ 0 ].length > 0 &&
                    <div className={css( styles.list )}>
                        {article.paragraphs.map( ( items: string, index: string ) => {
                            return <p key={index} className={css( styles.paragraphs )}
                                      dangerouslySetInnerHTML={{__html: items}}/>;
                        } )}
                    </div>}
                    <div className={css( styles.linkGroup )}>
                        <ArrowUp/>
                        <a href='/' onClick={( e ) => {
                            handleScrollClick( e );
                        }} className={css( styles.links )}>
                            {data.copy.scroll_button_text}
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Article;
