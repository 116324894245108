import React from 'react';
import { css } from 'aphrodite/no-important';
import { style } from './style';

const loadingImg = 'images/loading.svg';

const Loading = () => {
    const styles = style();
    return (
      <section className={css(styles.loading)}>
        <img className={css(styles.loadingImage)} src={loadingImg} alt="Loading" />
      </section>
    );
}

export default Loading;
