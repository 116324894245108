import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { APP_MAX_WIDTH, media } from 'util/constants';
import { children } from '../../util/style-helpers';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  grid: {
    fontFamily: 'DrukCyTT, sans-serif',
    margin: '0 auto',
    maxWidth: APP_MAX_WIDTH,
    textAlign: 'center',
    width: '100%'
  },

  hero: {
    width: '100%',
    margin: 'auto'
  },

  heroDesktop: {
    display: 'none',

    [media.desktop]: {
      display: 'block'
    }
  },

  heroMobile: {
    display: 'block',

    [media.desktop]: {
      display: 'none'
    }
  },

  articles: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '5px',

    [media.mobile]: {
      margin: '15px',
    },

    [media.tablet]: {
      margin: '32px 15px'
    },

    [media.desktop]: {
      margin: '32px 0'
    }
  },

  thumbnail: {
    height: '150px',

    [media.mobile]: {
      height: '185px'
    },

    [media.tablet]:{
      height: '245px'
    }
  },

  article: {
    textAlign: 'left',
    width: '100%',
    margin: '5px',
    
    [media.desktop]: {
      width: 'calc(50% - 20px)',
      margin: '10px'
    },

    [children('a')]: {
      display: 'flex'
    }
  },

  articleInfo: {
    background: '#000000',
    padding: '5px 10px',
    boxSizing: 'border-box',
    height: '150px',

    [media.mobile]: {
      padding: '10px',
      height: '185px',
    },

    [media.tablet]: {
      height: '245px',
    }
  },

  headline: {
    color: '#D02126',
    fontFamily: 'DrukCyTT, sans-serif',
    fontSize: '18px',
    lineHeight: '20px',
    marginBottom: '2px',
    textTransform: 'uppercase',

    [media.mobile]: {
      fontSize: '20px',
      marginBottom: '5px',
    },

    [media.tablet]: {
      marginBottom: '10px',
      fontSize: '25px',
      lineHeight: '27px'
    }
  },

  date: {
    fontFamily: 'DrukCyTT, sans-serif',
    color: '#3e3e3e',
    fontSize: '18px',
    marginBottom: '5px',

    [media.mobile]: {
      marginBottom: '10px',
    },

    [media.tablet]: {
      marginBottom: '15px'
    }
  },

  test: {
    color: '#ffffff',
    fontFamily: 'Roboto, sans-serif',
    fontSize: '10px',
    lineHeight: '13px',

    [media.mobile]: {
      fontSize: '11px',
      lineHeight: '17px',
    },

    [media.tablet]: {
      fontSize: '12px',
      lineHeight: '20px',
    }
  }
});
