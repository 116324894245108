import axios from 'axios';
import { ENDPOINTS } from './constants';

export const checkIfInRegion = ( userCountry: string, allowedCountries: string ) => {
	if ( !userCountry || !allowedCountries ) return false;
	const countries = allowedCountries.split( ',' ).map( item => item.trim() );
	return countries.indexOf( userCountry ) >= 0;
}

export const fetchGeo = () => {
	return axios
	.get( ENDPOINTS.API_GEO )
	.then( ( res ) => {
		return res.data
	} )
	.catch( err => {
		return null
	} );
}

export const getQSPs = () => {
	const qsps = Object.create( null );

	window.location.search
		  .slice( 1 )
		  .split( '&' )
		  .forEach( ( item ) => {
			  const [key, value = ''] = item.split( '=' );
			  qsps[ key ] = value;
		  } );

	return qsps;
};

export const isTrue = ( value: string ) => {
	return ['true', '1', true, 1, 'yes'].indexOf( value ) >= 0;
};

/**
 * Creates a cookie for given name and value.
 * Default lifetime is 1 year.
 */
export const createCookie = ( name: string, value: any, days: number = 1 * 365 ) => {
	var expires = '';

	if ( days ) {
		var date = new Date();
		date.setTime( date.getTime() + (days * 24 * 60 * 60 * 1000) );
		expires = '; expires=' + date.toUTCString();
	}

	document.cookie = name + '=' + value + expires + '; path=/';
}

/**
 * Retreives a cookie by a given name
 */
export const readCookie = ( name: string ): string => {
	var nameEQ = name + '=';
	var ca = document.cookie.split( ';' );

	for ( var i = 0; i < ca.length; i++ ) {
		var c = ca[ i ];
		while ( c.charAt( 0 ) === ' ' ) c = c.substring( 1, c.length );
		if ( c.indexOf( nameEQ ) === 0 ) return c.substring( nameEQ.length, c.length );
	}

	return '';
}

/**
 * Erases a cookie by a given name
 */
export const eraseCookie = ( name: string ): void => {
	createCookie( name, '', -1 );
}
