import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { media } from 'util/constants';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  closed: {
    textAlign: 'center',
    paddingTop: '93.5px',

    [media.tablet]: {
      paddingTop: '122px'
    }
  },
  headline: {
    fontFamily: 'DrukCyTT, sans-serif',
    fontSize: '32px',

    [media.tablet]: {
      fontSize: '48px'
    }
  },
  description: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '16px',
    lineHeight: '18px',

    [media.tablet]: {
      fontSize: '20px',
      lineHeight: '24px'
    }
  }
});
