import React from 'react';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { IComponentObject } from 'models/base';
import { FacebookIcon, InstagramIcon, TwitterIcon, YoutubeIcon } from '../../util/icons';
import { trackGoogleEvent } from '../../util/google-helpers';

const Footer = (props:IComponentObject) => {
    const { data } = props;
    const styles = styleSheet({});

    function getLinksList() {
        return Object.values(data.copy.links).map(

            (footerLink: any, i) => {
                if( footerLink && footerLink.href && footerLink.copy ) {
                    return (
                        <React.Fragment key={'footer_link'+i}>
                            <li onClick={()=>{trackGoogleEvent('Click', 'footer', footerLink.copy)}}
                                key={footerLink.copy}>
                                <span>|</span>
                                <a href={footerLink.href} target="_blank" rel="noopener noreferrer">{footerLink.copy}</a>
                            </li>
                        </React.Fragment>
                    );
                }
                return "";
            }
        );
    }

    return (
        <footer className={css( styles.footer )}>
            <div className={css( styles.wrapper )}>
                <ul className={css( styles.footerLinks )}>
                    {getLinksList()}
                </ul>
                <div className={css(styles.flex)}>
                    {data.copy.legal_line &&
                    <p className={css( styles.legalLine )}
                       dangerouslySetInnerHTML={{__html: data.copy.legal_line}}></p>}
                    <div className={css( styles.socialLinks )}>
                        {data.icons.twitter && data.icons.twitter.href &&
                        <a onClick={() => {
                            trackGoogleEvent( 'Click', 'footer', 'twitter' )
                        }}
                           href={data.icons.twitter.href} target="_blank" rel="noopener noreferrer"><TwitterIcon/></a>}
                        {data.icons.facebook && data.icons.facebook.href &&
                        <a onClick={() => {
                            trackGoogleEvent( 'Click', 'footer', 'facebook' )
                        }}
                           href={data.icons.facebook.href} target="_blank"
                           rel="noopener noreferrer"><FacebookIcon/></a>}
                        {data.icons.instagram && data.icons.instagram.href &&
                        <a onClick={() => {
                            trackGoogleEvent( 'Click', 'footer', 'instagram' )
                        }}
                           href={data.icons.instagram.href} target="_blank"
                           rel="noopener noreferrer"><InstagramIcon/></a>}
                        {data.icons.youtube && data.icons.youtube.href &&
                        <a onClick={() => {
                            trackGoogleEvent( 'Click', 'footer', 'youtube' )
                        }}
                           href={data.icons.youtube.href} target="_blank" rel="noopener noreferrer"><YoutubeIcon/></a>}
                    </div>
                </div>
            </div>
        </footer>
    );
};


export default Footer;
