import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { IComponentObject } from 'models/base';
import { styleSheet } from './style';
import { trackGoogleEvent, trackGooglePage } from '../../util/google-helpers';
import { GA_PAGES } from '../../util/constants';

const Home = (props:IComponentObject) => {
    window.scroll(0,0);

    const { data } = props;
    const styles = styleSheet({});

    useEffect(() => {
        trackGooglePage(GA_PAGES.HOME);
    });


    return (
      <section className={css( styles.home )}>
          <div className={css( styles.pattern )}>
              <div className={css( styles.wrapper )}>
                  {data.image &&
                  <img className={css( styles.image )} src={data.image.src} width={data.image.width} alt={data.image.alt}/>}
                  {data.paragraph &&
                  <div className={css( styles.paragraph )}>
                      <p dangerouslySetInnerHTML={{__html: data.paragraph}}/>
                      <div className={css( styles.signature )}>
                          <img src={data.signed_image} alt="signature"/>
                          <p>{data.signed }</p>
                      </div>
                  </div>}
              </div>
          </div>
          <div className={css(styles.featured)}>
              <p className={css( styles.featuredTitle )}>{data.featured.title}</p>
              <div className={css( styles.featuredWrapper )}>
                  <img className={css( styles.featuredImage )}
                       src={data.featured.image}
                       alt={data.featured.image_alt}/>
                  <div className={css(styles.featuredText)}>
                      <p dangerouslySetInnerHTML={{__html: data.featured.text}}></p>
                      <Link to={data.featured.link_to_post} onClick={() => trackGoogleEvent( 'Click', 'Featured button', data.featured.link_to_post)}>
                          <button className={css( styles.featuredButton )}>{data.featured.button}</button>
                      </Link>
                  </div>
              </div>
          </div>
      </section>
    );
};

export default Home;
