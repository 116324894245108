import App from "components/app";
import Loading from "components/loading";
import React, { useState } from "react";
import { LOCALES, ROUTES } from 'util/constants';
import { IGenericObject } from 'models/base';
import { createCookie, readCookie } from "util/helpers";

const Multilanguage = () => {
    const [locale, setLocale] = useState<undefined | string>(undefined);
    const [widgetId, setWid] = useState<undefined | string>(undefined);
    const [appLoaded, setAppLoaded] = useState(false);
    const [routes, setRoutes] = useState<IGenericObject>({});
    const availableLocales = [
        "en",
        "fr",
        "de",
        "br"
    ];

    const updateLocale = (locale: string) => {
        if (LOCALES.hasOwnProperty(locale)) {
            setWid(`${LOCALES[locale]}?t=${Date.now()}`);
            setRoutes({
                AGE_GATE: `/${locale}${ROUTES.AGE_GATE}`,
                CLOSED: `/${locale}${ROUTES.CLOSED}`,
                GEO: `/${locale}${ROUTES.GEO}`,
                HOME: `/${locale}${ROUTES.HOME}`,
                NEWS: `/${locale}${ROUTES.NEWS}`
            });
            setLocale(locale);
            createCookie("user_language", locale);
        }
    };

    if (!appLoaded) {
        // Detect user language based on the following conditions:
        // 1 - Locale URL is the first option to check.
        // 2 - Language cookies if base URL is provided.
        // 3 - If no language cookies available, application is going to check the browser preferences.
        // 4 - If no conditions are met, default to English.
        let userLanguage: undefined | string = undefined;

        // 1. Check if URL has language path.
        window.location.pathname.split("/").map((segment, index) => {
            if (index === 1) {
                availableLocales.map(locale => userLanguage = locale === segment ? locale : userLanguage );
            }
            return false;
        });

        // 2. Check cookies for language.
        if (!userLanguage) {
            const cookieLanguage = readCookie("user_language");
            availableLocales.map(locale => userLanguage = locale === cookieLanguage ? locale : userLanguage );
        }

        // 3. Look for language on browser preferences.
        if (!userLanguage) {
            navigator.languages.map(browserLocale => {
                return availableLocales.map(locale => {
                    if (!userLanguage) {
                        userLanguage = locale === browserLocale ? locale : userLanguage
                    }
                    return false;
                });
            });
        }

        // 4. Assign English by default.
        if (!userLanguage) {
            userLanguage = "en";
        }

        updateLocale(userLanguage);
        setAppLoaded(true);
    }

    return(
        locale ? <App locale={locale} updateLocale={updateLocale} widgetId={widgetId} routes={routes} /> : <Loading />
    );
}

export default Multilanguage;
