import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { trackGoogleEvent } from 'util/google-helpers';
import { ArrowDown } from '../../util/icons';

const Header = (props: any) => {
    const { data, updateLocale, currentLocale, routes, hideNavigation } = props;
    const styles = styleSheet({});
    const [openDropdown, setOpenDropdown] = useState<boolean>(false);

    function selectLocale( locale: string ) {
        setOpenDropdown( false );
        updateLocale( locale );
    }

    function getLocalesList() {
        return Object.values(data.locales).map(

            (locale: any, i) => {
                if( locale && locale.image && locale.short_name ) {
                    return (
                        <li key={locale.short_name} className={currentLocale === locale.short_name ? 'selected' : ''}>
                            <button onClick={() => {
                                trackGoogleEvent('Click', 'locale', locale.short_name);
                                selectLocale( locale.short_name );
                            }}>
                                <img className={css(styles.flag)} src={locale.image} alt={locale.image_alt}/>
                                {locale.short_name.toUpperCase()}
                            </button>
                        </li>
                    );
                }
                return "";
            }
        );
    }

    function getFlagsList() {
        return Object.values(data.locales).map(

            (locale: any, i) => {
                if( locale && locale.image && locale.short_name ) {
                    return (
                        <img key={'flag_'+locale.short_name} className={currentLocale === locale.short_name ? css(styles.flag) : css(styles.hide)}
                             src={locale.image} alt={locale.image_alt}/>
                    );
                }
                return "";
            }
        );
    }

    return (
      <header className={css(styles.header)}>
        <div className={css(styles.wrapper)}>
          { data.urls.logo_image && data.urls.logo_url &&
            <a href={ data.urls.logo_url } target={ data.urls.logo_url_target } rel='noopener noreferrer'>
              <img src={ data.urls.logo_image } alt={ data.copy.logo_alt } className={css(styles.logo)}/>
            </a> }
          { data.urls.logo_image && !data.urls.logo_url &&
            <img src={ data.urls.logo_image } alt={ data.copy.logo_alt } className={css(styles.logo)}/> }
            <div className={css(styles.headerActions)}>
                {data.inRegion &&
                    <div className={hideNavigation ? css(styles.hide) : css( styles.links )}>
                        <NavLink exact to={routes.HOME}
                                onClick={() => trackGoogleEvent( 'Click', 'navigation', routes.HOME )}
                                className={css( styles.linkNav )}>{data.copy.link_home}</NavLink>
                        <NavLink exact to={routes.NEWS}
                                onClick={() => trackGoogleEvent( 'Click', 'navigation', routes.NEWS )}
                                className={css( styles.linkNav )}>{data.copy.link_news}</NavLink>
                        <a className={css( styles.linkNav )}
                           onClick={() => trackGoogleEvent( 'Click', 'navigation', 'Store: ' + data.copy.link_store_url )}
                           target="_blank" href={data.copy.link_store_url}
                        rel="noopener noreferrer">{data.copy.link_store}</a>
                    </div>
                }
                <input className={css( styles.trigger )}
                       type="checkbox"
                       readOnly
                       checked={openDropdown}
                       onMouseEnter={() => {setOpenDropdown( true )}}
                       onMouseLeave={() => {setOpenDropdown( false )}}/>

                <input className={css( styles.triggerMobile )}
                       type="checkbox"
                       readOnly
                       checked={openDropdown}
                       onClick={() => { setOpenDropdown( !openDropdown )}}/>
                <div className={css( styles.currentLocale )}>
                    {getFlagsList()}
                    {currentLocale}
                    <ArrowDown/>
                </div>
                <ul className={ openDropdown ? css( styles.localesDropdown ) : css( styles.hide ) }
                    onMouseLeave={() => setOpenDropdown( false )}
                    onMouseEnter={() => setOpenDropdown( true )}>
                    {getLocalesList()}
                </ul>
            </div>
        </div>
      </header>
    );
}

export default Header;
