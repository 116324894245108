import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { APP_MAX_WIDTH, media } from 'util/constants';
import { children } from '../../util/style-helpers';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  footer: {
    flexGrow: 1,
    fontFamily: 'DrukCyTT, sans-serif',
    backgroundColor: '#000000',
    color: '#ffffff',
    padding: '15px 10px 22px',

    [media.tablet]: {
      padding: '21px 20px'
    }
  },

  wrapper: {
    margin: '0 auto',
    maxWidth: APP_MAX_WIDTH,
    fontSize: '16px',
    letterSpacing: '1px',
    overflow: 'hidden',

    [media.tablet]: {
      fontSize: '18px'
    }
  },

  flex: {
    display: 'flex',
    flexDirection: 'column',

    [media.tablet]: {
      flexDirection: 'row'
    }
  },

  footerLinks: {
      listStyle: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      padding: 0,
      marginBottom: '5px',
      marginLeft: '-15px',

      [children('li')]: {
        position: 'relative',
        whiteSpace: 'nowrap',
        padding: '0 15px',

        [children('a')]: {
          color: '#ffffff'
        }
      },

      [children('span')]: {
        position: 'absolute',
        color: '#D02126',
        left: '-2px'
      }
  },

  legalLine: {
    color: '#ffffff',
    fontSize: '9px',
    letterSpacing: '0',
    margin: '5px 0',
    fontFamily: 'Roboto, sans-serif',

    [children('a')]: {
      textDecoration: 'underline'
    }
  },

  socialLinks: {
    display: 'flex',
    textAlign: 'right',
    justifyContent: 'center',
    marginTop: '10px',

    [media.tablet]: {
      marginLeft: '120px',
      marginTop: '0',
      justifyContent: 'initial'
    },

    [children('svg')]: {
      fill: '#ffffff',
      height: '15px',
      margin: '0 10px',

      [media.tablet]: {
        margin: '0'
      }
    }
  }
});
