import React from 'react';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { IComponentObject } from 'models/base';

const Closed = (props:IComponentObject) => {
    const { data } = props;
    const styles = styleSheet({});

    return (
      <section className={css(styles.closed)}>
        { data.copy.headline &&
          <h1 className={css(styles.headline)}>{ data.copy.headline }</h1> }
        { data.copy.headline &&
          <p className={css(styles.description)}>{ data.copy.description }</p> }
      </section>
    );
}

export default Closed;
