import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { createCookie } from 'util/helpers';
import { AGE_GATE, GA_PAGES } from '../../util/constants'
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { trackGoogleEvent, trackGooglePage } from '../../util/google-helpers';

export interface iAgeGateProps {
    onSuccess: Dispatch<SetStateAction<boolean>>,
    data: any
};

const AgeGate = (props: iAgeGateProps) => {
    const styles = styleSheet({'backgroundUrl': props.data.background_image ? props.data.background_image : ''});
    const [day, setDay] = useState<number|null>(null);
    const [month, setMonth] = useState<number|null>(null);
    const [year, setYear] = useState<number|null>(null);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        trackGooglePage(GA_PAGES.AGE_GATE);
    });

    const changeDay = (event: any) => {
        setDay(parseInt(event.target.selectedIndex));
    };

    const changeMonth = (event: any) => {
        setMonth(parseInt(event.target.selectedIndex));
    };

    const changeYear = (event: any) => {
        setYear(parseInt(event.target.value));
    };

    const submitAgeGate = () => {
        const d = new Date();
        const maxBirthDate = new Date(d.getFullYear() - AGE_GATE.minAge, d.getMonth(), d.getDate());
        if ( year && month && day ) {
            const birthDate = new Date(year, month - 1, day);
            const allowed = maxBirthDate.getTime() >= birthDate.getTime();
            if (allowed) {
                setError(false);
                createCookie(AGE_GATE.cookieName, 1, AGE_GATE.cookieExpirationDays);
                props.onSuccess(maxBirthDate.getTime() >= birthDate.getTime());
            } else {
                setError(true);
            }
        }
    };

    const daysArray = Array.from(Array(AGE_GATE.monthLength).keys());
    const monthsArray = AGE_GATE.calendar;
    const yearsArray = Array.from(Array(AGE_GATE.yearsLength).keys()).reverse();


    return (
        <section className={css(styles.wrapper)}>
            <div className={css(styles.ageGate)}>
                { props.data.logo && props.data.logo_alt &&
                <div className={css(styles.logo)}>
                    <img src={props.data.logo} alt={props.data.logo_alt}/>
                </div>}
                { props.data.headline && <p className={css(styles.headline)}>{props.data.headline}</p>}
                <div className={css(styles.selectContainer)}>
                    <select onChange={(e) => {
                        trackGoogleEvent('Click', 'Age gate', 'Change month');
                        changeMonth(e);
                    }}>
                        <option disabled={!!month}>{props.data.placeholder.month}</option>
                        {monthsArray.map(monthItem => <option key={monthItem.id} value={monthItem.name} id={'' + monthItem.id}>{monthItem.name}</option>)}
                    </select>
                    <select onChange={(e) => {
                        trackGoogleEvent('Click', 'Age gate', 'Change day');
                        changeDay(e);
                    }}>
                        <option disabled={!!day}>{props.data.placeholder.day}</option>
                        {daysArray.map((element) => <option key={element + 1} id={'' + (element + 1)}>{element + 1 > 9 ? element + 1 : '0' + (element + 1)}</option>)}
                    </select>
                    <select onChange={(e)=>{
                        trackGoogleEvent('Click', 'Age gate', 'Change year');
                        changeYear(e);
                    }}>
                        <option disabled={!!year}>{props.data.placeholder.year}</option>
                        {yearsArray.map((element) => <option key={element}>{element + (new Date()).getFullYear() - AGE_GATE.yearsLength}</option>)}
                    </select>
                </div>
                <div className={css(styles.submitContainer)}>
                    {error && <p className={css(styles.error)}>{props.data.error}</p>}
                    <button className={day && year && month ? css(styles.active) : css(styles.inactive)}
                            onClick={() => {
                                trackGoogleEvent('Click', 'Age gate', 'Submit');
                                submitAgeGate();
                            }}>{props.data.submit}</button>
                    <p className={css(styles.extra)} dangerouslySetInnerHTML={{__html: props.data.extra}}></p>
                </div>
            </div>
        </section>
        );

};

export default AgeGate;
