export const DEFAULT_WID = '3130d087b6a9d062'; // PROD
// export const DEFAULT_WID = '810090077815f1ee'; // DEV
export const DEFAULT_POLL_RATE = 30000;

// Endpoints
export const ENDPOINTS = {
  API_GEO: '/api/getregion.json'
};

// Google Analytics
export const GA_PAGES = {
  GEO: 'Out of Geo',
  AGE_GATE: 'Age Gate',
  HOME: 'Home',
  NEWS: 'News',
  NEW_SINGLE: 'New Single'
};

// Routes
export const ROUTES = {
  AGE_GATE: '/agegate',
  CLOSED: '/closed',
  GEO: '/geo',
  HOME: '/',
  NEWS: '/news'
};

// Locales
export const LOCALES: any = {
  en: "3130d087b6a9d062",
  fr: "51209007f699a024",
  de: "d1008067269970ac",
  br: "b1908067d6697061"
}

// Articles
export const ARTICLE_TYPES = {
  ARTICLE: 'article',
  RECIPE: 'recipe'
};

// Styles
export const APP_MAX_WIDTH = '924px';

export const breakpoints = {
  mobile: 350,
  tablet: 768,
  desktop: 1025,
  xl: 1920,
};

// Breakpoints
export const media = {
  mobile: `@media (min-width: ${breakpoints.mobile}px)`,
  tablet: `@media (min-width: ${breakpoints.tablet}px)`,
  desktop: `@media (min-width: ${breakpoints.desktop}px)`,
  xl: `@media (min-width: ${breakpoints.xl}px)`
};

// Agegate and datepicker constants
export const AGE_GATE = {
  cookieName: 'ps_ag',
  cookieExpirationDays: 7,
  minAge: 18,
  yearsLength: 101,
  monthLength: 31,
  calendar: [
    { id: 1, name: '01' },
    { id: 2, name: '02' },
    { id: 3, name: '03' },
    { id: 4, name: '04' },
    { id: 5, name: '05' },
    { id: 6, name: '06' },
    { id: 7, name: '07' },
    { id: 8, name: '08' },
    { id: 9, name: '09' },
    { id: 10, name: 10 },
    { id: 11, name: 11 },
    { id: 12, name: 12 }
  ]
};
