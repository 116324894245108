import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { APP_MAX_WIDTH, media } from 'util/constants';
import { children } from '../../util/style-helpers';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  home: {
    fontFamily: 'Roboto, sans-serif',
    color: '#ffffff',
    margin: '0 auto',
    width: '100%'
  },

  pattern: {
    backgroundColor: '#121212',
    backgroundImage: 'url("/images/vector_background.png")',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },

  wrapper: {
    maxWidth: APP_MAX_WIDTH,
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px 5px',

    [media.mobile]: {
      padding: '30px 32px',
    },

    [media.tablet]: {
      padding: '50px 20px',
      flexDirection: 'row',
    }
  },

  image: {
    maxWidth: '90%'
  },

  paragraph: {
    width: '100%',
    fontSize: '12px',
    textAlign: 'left',
    lineHeight: '16px',
    margin: '20px 10px 15px',
    padding: '20px 0 0 0',
    borderTop: '2px solid #D02126',
    borderLeft: 'none',

    [media.tablet]: {
      fontSize: '14px',
      lineHeight: '23px',
      borderTop: 'none',
      borderLeft: '2px solid #D02126',
      padding: '0 0 0 15px'
    },

    [media.desktop]: {
      margin: '0px 0px 15px 20px'
    }
  },

  signature: {
    border: 'none',
    paddingTop: '0',
    marginTop: '0',

    [children('img')]: {
      width: '90px',

      [media.desktop]: {
        width: '120px'
      }
    },
  },

  featured: {
    margin: '25px auto',
    maxWidth: APP_MAX_WIDTH,
    padding: '0 15px',

    [media.tablet]:{
      padding: '0 20px'
    }
  },

  featuredWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    [media.tablet]: {
      flexDirection: 'row'
    }
  },

  featuredTitle: {
    fontFamily: 'DrukCyTT',
    fontSize: '25px',
    textTransform: 'uppercase',
    marginBottom: '5px'
  },

  featuredImage: {
    margin: '0 auto 10px auto',
    width: '100%',

    [media.tablet]: {
      width: '60%'
    }
  },

  featuredText: {
    fontSize: '12px',
    lineHeight: '16px',

    [media.tablet]: {
      marginLeft: '20px',
      fontSize: '14px',
      lineHeight: '23px',
    }
  },

  featuredButton: {
    cursor: 'pointer',
    fontFamily: 'DrukCyTT',
    background: '#D02126',
    color: '#000000',
    textTransform: 'uppercase',
    border: 'none',
    fontSize: '18px',
    marginTop: '10px',
    padding: '0 25px',

    'a': {
      color: '#212121'
    }
  }
});
