import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { children } from '../../util/style-helpers';
import { media } from '../../util/constants';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  carousel: {

    [children('.control-dots')]: {
      display: 'flex',
      justifyContent: 'center',
      zIndex: 50
    },

    [children('.dot')]: {
      background: '#211d22',
      maxWidth: '80px',
      height: '4px',
      borderRadius: '0',
      outline: '0',
      opacity: 1,
      transition: 'none',
      flexGrow: 1,
      zIndex: 50
    },

    [children('.dot.selected')]: {
      background: '#d60a09',
      opacity: 1,
      filter: 'none'
    },

    [children( '.desktop' )]: {
      display: 'none',

      [ media.desktop ]: {
        display: 'inline'
      }
    },
    [children( '.mobile' )]: {
      display: 'inline',

      [ media.desktop ]: {
        display: 'none'
      }
    }
  },

  link: {
    display: 'block',
    height: '100%'
  }
});
