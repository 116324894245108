import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { css } from 'aphrodite/no-important';
import { styleSheet } from './style';
import { IGridObject, IGenericObject } from 'models/base';
import { trackGoogleEvent, trackGooglePage } from 'util/google-helpers';
import { CSSProperties } from "react";
import { GA_PAGES } from '../../util/constants';

const ellipsis: CSSProperties = {
    display: '-webkit-box',
    WebkitLineClamp: 5,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
};

const Grid = (props:IGridObject) => {
    const { data, list, routes } = props;
    const styles = styleSheet({});

    useEffect(() => {
        trackGooglePage(GA_PAGES.NEWS);
    });

    const handleScrollClick = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    };


    return (
        <section>
            { data.copy.image &&
            <img className={css(styles.hero, styles.heroDesktop)} src={ data.copy.image } alt={ data.copy.image_alt } /> }
            { data.copy.image_mobile &&
            <img className={css(styles.hero, styles.heroMobile)} src={ data.copy.image_mobile } alt={ data.copy.image_alt } /> }
            <section className={css(styles.grid)}>
                <div className={css(styles.articles)}>
                    { list.map((article:IGenericObject) => {
                        return (
                            <div className={css(styles.article)} key={ article.id }>
                                <Link to={ routes.NEWS + '/' + article.id }
                                      onClick={() => {
                                          handleScrollClick();
                                          trackGoogleEvent( 'Click', 'article', routes.NEWS + '/' + article.id );
                                      }} >
                                        <img src={ article.thumbnail }
                                             alt={ article.thumbnail_alt }
                                             className={css(styles.thumbnail)} />
                                        <div className={css(styles.articleInfo)}>
                                            <p className={css(styles.headline)}>{article.headline}</p>
                                            <p className={css(styles.date)}>{article.date}</p>
                                            <p style={ellipsis} className={css(styles.test)} dangerouslySetInnerHTML={{ __html: article.paragraphs[0]}}></p>
                                        </div>
                                </Link>
                            </div>
                        );
                    }) }
                </div>
            </section>
        </section>
    );
}

export default Grid;
