import { StyleSheet } from 'aphrodite/no-important';
import { IGenericObject } from 'models/base';
import { media } from 'util/constants';
import { children } from '../../util/style-helpers';

export const styleSheet = (styles:IGenericObject) => StyleSheet.create({
  wrapper: {
    textAlign: 'center',
    paddingTop: '93.5px',
    height: '400px',
    background: styles.backgroundUrl ? 'url('+styles.backgroundUrl+')' : '',
    backgroundColor: '#000000',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',

    [media.tablet]: {
      paddingTop: '122px'
    },

    '@media (min-width: 1150px)': {
      paddingTop: '122px',
      backgroundSize: 'contain',
    }
  },
  ageGate: {
    background: '#000000',
    width: '500px',
    maxWidth: '100%',
    minHeight: '300px',
    margin: 'auto',
    boxSizing: 'border-box',
    padding: '20px',
    textAlign: 'left',

    [media.tablet]: {
      padding: '50px 70px',
    }
  },

  logo: {
    [children('img')]: {
      height: '20px',
    },
    textAlign: 'center'
  },

  selectContainer: {
    marginTop: '10px',
    whiteSpace: 'nowrap',

    [children('select')]: {
      background: '#ffffff',
      WebkitAppearance: 'none',
      fontSize: '17px',
      letterSpacing: '1px',
      height: '30px',
      borderRadius: '2px',
      border: 'none',
      fontFamily: 'DrukCyTT, sans-serif',
      textTransform: 'uppercase',
      marginRight: '7px',
      width: 'calc(33% - 4px)',
      paddingLeft: '10px',

      [media.desktop]: {
        height: '35px',
      },

      ':last-child': {
        marginRight: '0'
      }
    }
  },

  headline: {
    fontFamily: 'DrukCyTT',
    color: '#D02126',
    textTransform: 'uppercase',
    fontSize: '30px',
    letterSpacing: '1px'
  },

  submitContainer: {
    marginTop: '20px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',

    [media.desktop]: {
      marginTop: '15px',
    },

    [children('button')]: {
      display: 'flex',
      justifyContent: 'center',
      fontFamily: 'DrukCyTT',
      textTransform: 'uppercase',
      width: '250px',
      height: '40px',
      fontSize: '30px',
      lineHeight: '33px',
      letterSpacing: '1px',

      [media.tablet]: {
        fontSize: '28px',
        lineHeight: '34px',
        width: '180px'
      }
    }
  },

  active:{
    background: '#D02126',
    color: '#000000',
    border: 'none',
    cursor: 'pointer'
  },

  inactive: {
    background: '#000000',
    color: '#212121',
    border: '1px solid #ffffff',
  },

  error: {
    color: '#D02126',
    fontFamily: 'Roboto, sans-serif',
    marginBottom: '20px',
    fontSize: '18px',
    width: '100%',
    textAlign: 'center',

    [media.desktop]: {
      fontSize: '16px',
      marginBottom: '15px',
    }
  },

  extra: {
    color: '#ffffff',
    fontSize: '12px',
    fontFamily: 'Roboto, sans-serif',
    width: '100%',
    marginTop: '20px',
    textAlign: 'center',

    [children('br')]: {
      display: 'none',

      [media.mobile]: {
        display: 'block'
      }
    },

    [media.desktop]: {
      marginBottom: '15px',
    },

    [children('a')]: {
      color: '#ffffff',
      textDecoration: 'underline'
    }
  }
});
